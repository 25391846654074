<template>
  <div>
    <div class="card card-custom card-nobody gutter-b">
      <div class="card-header">
        <div class="card-title div-center">
          <h3 class="card-label">Fiche prestataire #{{prestataire.id}}</h3>
        </div>
        <div class="card-toolbar div-center">
          <b-button v-if="deletable" variant="danger" size="sm" @click="deletePresta()" class="btn-icon mr-1"><i class="icon-md fas fa-trash"></i></b-button>
          <b-button variant="info" size="sm" class="btn-icon mr-1" v-b-modal.calendar><i class="icon-md fas fa-calendar"></i></b-button>
          <b-button variant="primary" size="sm" @click="toggleFiles = !toggleFiles" class="btn-icon mr-1"><i class="icon-md fas fa-file"></i></b-button>
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save" v-if="$store.getters.currentUser.role === 1">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3" :class="toggleFiles ? '' : 'd-none'">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{$store.back.getText('liste-fichier')}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button variant="primary" size="sm" class="btn-icon mr-1" v-b-modal.import><i class="icon-md fas fa-plus"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <div>
          <b-row>
            <b-button size="md" variant="success" @click="docs_filter = null" class="ml-5 mr-1">Tout</b-button>
            <b-button size="md" variant="primary" class="mr-1" v-for="(t,i) in params.GEDType"  @click="docs_filter = t.id"  :key="i">{{t.titre.fr}}</b-button>
          </b-row>
          <b-row class="mt-2">
            <b-col md="2" v-for="(d,i) in docsFiltered" :key="i">
              <div class="card text-center border-primary">
                <div class="card-header bg-primary text-white" style="padding: 1rem 1.25rem;">{{getFileName(d)}}</div>
                <div class="card-body" style="padding:1.5rem;">
                  <h6 class="card-subtitle text-muted mb-2">{{params.GEDType.find(t => t.id === d.ged_type).titre.fr}}</h6>
                  <h6 class="card-subtitle text-muted mb-2 mt-1">{{$store.back.timestampToDate(d.date_create,false)}}</h6>
                  <b-card-text>
                    <b-button-group>
                      <b-button size="sm" variant="primary" :href="d.url" target="_blank"><i class="fas fa-eye"></i></b-button>
                      <b-button size="sm" variant="danger" @click="removeFile(i)"><i class="fas fa-minus"></i></b-button>
                    </b-button-group>
                  </b-card-text>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <b-tabs pills card>
      <b-tab :title="$store.back.getText('generale')" active class="tab-card-body">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('info-presta')}}</h3>
            </div>
          </div>
          <div class="card-body">
            <b-row>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('type')+':'">
                  <select class="form-control" v-model="prestataire.type_id">
                    <option v-for="(t,i) in params.PrestataireType" :key="i" :value="t.id">{{t.titre.fr}}</option>
                  </select>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('societe')+':'">
                  <b-form-input v-model="prestataire.societe" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('last_name')+':'">
                  <b-form-input v-model="prestataire.nom" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('first_name')+':'">
                  <b-form-input v-model="prestataire.prenom" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('phone')+':'">
                  <b-form-input v-model="prestataire.telephone" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('email')+':'">
                  <b-form-input v-model="prestataire.email" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('numero_ste')+':'">
                  <b-form-input v-model="prestataire.numero_ste" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('adresse')+':'">
                  <b-form-input v-model="prestataire.adresse"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('ville')+':'">
                  <b-form-input v-model="prestataire.ville"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$store.back.getText('pays')+':'">
                  <b-form-input v-model="prestataire.pays"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group label="Libellé du compte">
                  <b-form-input v-model="prestataire.banque.libelle_compte" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group :label="$store.back.getText('banque')+':'">
                  <b-form-input v-model="prestataire.banque.nom" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group :label="$store.back.getText('agence')+':'">
                  <b-form-input v-model="prestataire.banque.agence" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group :label="$store.back.getText('compte')+':'">
                  <b-form-input v-model="prestataire.banque.compte" required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$store.back.getText('finance')" class="tab-card-body" v-if="prestataire.type_id === 4">
        <div class="card card-custom gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('compte')}}</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="primary" size="sm" @click="addQuote()" class="btn-icon mr-1"><i class="icon-md fas fa-plus"></i></b-button>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Propriete</th>
                  <th scope="col">Client</th>
                  <th scope="col">Titre</th>
                  <th scope="col">Quantite</th>
                  <th scope="col">Prix HT</th>
                  <th scope="col">Total HT</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c,i) in comptes_fournitures" :key="i">
                  <td scope="row">
                    <a :href="'/#/propriete/detail/'+c.id">#{{c.id}}</a>
                  </td>
                  <td scope="row">
                    <a :href="'/#/client/detail/'+c.client_id">#{{c.client_id}}</a>
                  </td>
                  <td scope="row">
                    <b-form-input v-model="c.fournitures.titre" readonly></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="c.fournitures.quantite" readonly></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="c.fournitures.prix_ht" readonly></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="c.fournitures.total" readonly></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-datepicker v-model="c.fournitures.date" readonly style="background:white"></b-form-datepicker>
                  </td>
                </tr>
                <tr>
                  <td scope="row"></td>
                  <td scope="row"></td>
                  <td scope="row"></td>
                  <td scope="row"></td>
                  <td scope="row"></td>
                  <td scope="row">
                    <b-form-input :value="totalFournitues" readonly></b-form-input>
                  </td>
                  <td scope="row"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$store.back.getText('finance')" class="tab-card-body" v-else>
        <div class="card card-custom gutter-b mt-3">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$store.back.getText('compte')}}</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="primary" size="sm" @click="addQuote()" class="btn-icon mr-1"><i class="icon-md fas fa-plus"></i></b-button>
            </div>
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Propriete</th>
                  <th scope="col">Client</th>
                  <th scope="col">Titre</th>
                  <th scope="col">Quantite</th>
                  <th scope="col">Prix HT</th>
                  <th scope="col">Total HT</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c,i) in comptes_prestataire" :key="i">
                  <td scope="row">
                    <a :href="'/#/propriete/detail/'+c.id">#{{c.id}}</a>
                  </td>
                  <td scope="row">
                    <a :href="'/#/client/detail/'+c.client_id">#{{c.client_id}}</a>
                  </td>
                  <td scope="row">
                    <b-form-input v-model="c.quotes.titre" readonly></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="c.quotes.quantite" readonly></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="c.quotes.prix_achat" readonly></b-form-input>
                  </td>
                  <td scope="row">
                    <b-form-input v-model.number="c.quotes.totalAchatHT" readonly></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td scope="row"></td>
                  <td scope="row"></td>
                  <td scope="row"></td>
                  <td scope="row"></td>
                  <td scope="row"></td>
                  <td scope="row">
                    <b-form-input :value="totalPrestataire" readonly></b-form-input>
                  </td>
                  <td scope="row"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div class="card-deck">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('commentaire')}}</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form-group :label="$store.back.getText('commentaire')+':'">
            <b-form-textarea v-model="prestataire.commentaire" rows="4" readonly></b-form-textarea>
          </b-form-group>
          <b-form-group :label="$store.back.getText('ajouter')+':'">
            <b-input-group>
              <b-form-input v-model="commentaireTmp" type="text" required placeholder="Entre votre commentaire"></b-form-input>
              <template #append>
                <b-button variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <b-modal id="import" title="Importation de document" ref="import">
      <b-form>
        <b-form-group label="Type:">
          <select class="form-control" v-model.number="tmpDoc.ged_type">
            <option v-for="(t,i) in params.GEDType" :key="i" :value="t.id">{{t.titre.fr}}</option>
          </select>
        </b-form-group>
        <b-form-group label="Dociment:">
          <b-form-file  @change="handleFile" placeholder="Selectionnez votre fichier a importer"></b-form-file>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button v-if="loaderImportFile === false" variant="primary" class="float-right" @click="addDoc()">
            Valide
          </b-button>
          <b-button v-else variant="info" size="sm" class="btn-icon mr-1 float-right"> <i class="fas fa-circle-notch fa-spin"></i></b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="calendar" title="Calendrier" ref="calendar">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="calendarTmp.name" type="text" required placeholder="Entre le titre"></b-form-input>
        </b-form-group>
        <b-form-group label="Detail:">
          <b-form-input v-model="calendarTmp.detail" type="text" required placeholder="Entre le deail"></b-form-input>
        </b-form-group>
        <b-form-group label="Debut:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart"></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeStart"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Fin:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart" readonly></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeEnd"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="saveCal()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.calendar.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      loaderImportFile:false,
      deletable:false,
      docs_filter:null,
      toggleFiles:false,
      tmpDoc:{
        "url": null,
        "ged_type": null,
        "date_create": null,
        "status": 1
      },
      calDateStart:null,
      calTimeStart:"12:00",
      calTimeEnd:"12:30",
      calendarTmp:{
        name: null,
        start: null,
        end: null,
        detail: null,
        url:`/${location.hash}`
      },
      commentaireTmp:null,
      prestataire:{
        "id": null,
        "societe":null,
        "nom": null,
        "prenom": null,
        "email": null,
        "telephone": null,
        "commentaire": "",
        "numero_ste":null,
        "banque":{
          "libelle_compte":null,
          "nom":null,
          "agence":null,
          "compte":null
        },
        "docs":[],
        "type_id": 1,
        "status": 1,
        "date_create": null,
        "date_update": null,
      },
      comptes_fournitures:[],
      comptes_prestataire:[],
      params:{
        PrestataireType:[],
        GEDType:[]
      }
    };
  },
  components: {
  },
  watch: {
    calDateStart(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
    },
  },
  methods: {
    save() {
      this.$store.back.ajax('/prestataire/create', this.prestataire, (res) => {
        if(res.status){
          if(this.prestataire.id === null){
            this.$router.push({path:'/prestataire/detail/'+res.data.id});
            setTimeout(() => {location.reload()},500);
          }else{
            this.prestataire = res.data;
            this.$bvToast.toast(`Prestataire enregistre !`, {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
          }
        }
      });
    },
    saveCal(){
      this.$store.back.ajax('/calendrier/create', this.calendarTmp, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre rdv a ete sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$refs.calendar.hide();
        }
      });
    },
    addCommentaire(){
      if(this.prestataire.commentaire === null){
        this.prestataire.commentaire = "";
      }
      var user = this.$store.back.getUser();
      var date = new Date();
      this.prestataire.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    getFileName(obj){
      var q = obj.url.slice(obj.url.indexOf("?")+1);
      var params = new URLSearchParams(q);
      var filename = params.get('q');
      filename = filename.slice(0,19-3)
      return filename+"...";
    },
    removeFile(index){
      this.prestataire.docs.splice(index,1);
    },
    handleFile(e){
      var the = this;
      this.loaderImportFile = true;
      var files = e.target.files;
      files.forEach((item) => {
        the.upladFile(item);
      });
    },
    upladFile(file){
      const fileUploaded = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name
        }
        this.$store.back.ajax('/ged/import', tmp, (res) => {
          if(res.status == true){
            this.tmpDoc.url = res.data;
            this.tmpDoc.date_create = Math.floor(Date.now() / 1000);
            this.loaderImportFile = false;
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    addDoc(){
      var tmp = JSON.stringify(this.tmpDoc);
      tmp = JSON.parse(tmp)
      this.prestataire.docs.push(tmp);
      this.tmpDoc.url = null;
      this.tmpDoc.ged_type = null;
      this.tmpDoc.date_create = null;
      this.$refs.import.hide();
    },
    deletePresta(){
      this.$store.back.ajax('/prestataire/delete/'+this.prestataire.id, null, (res) => {
        if(res.status === true){
          this.$router.push({path:"/prestataire/liste"});
        }
      })
    }
  },
  computed: {
    totalFournitues(){
      var total = 0;
      this.comptes_fournitures.forEach((item) => {
        total += parseFloat(item.fournitures.total);
      });
      return total
    },
    totalPrestataire(){
      var total = 0;
      this.comptes_prestataire.forEach((item) => {
        total += parseFloat(item.quotes.totalAchatHT);
      });
      return total
    },
    docsFiltered(){
      if(this.docs_filter === null){
        return this.prestataire.docs;
      }else{
        return this.prestataire.docs.filter(d => d.ged_type === this.docs_filter);
      }
    }
  },
  beforeMount(){
    if(this.$route.params.id != undefined){
      this.$store.back.ajax(`/prestataire/${this.$route.params.id}`, null, (res) => {
        if(res.status){
          this.prestataire = res.data.prestataire;
          this.deletable = res.data.deletable;
        }
      });
    }
    let params = {
      modules: ["PrestataireType","GEDType"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.status = res.data['status'];
        this.params.PrestataireType = res.data['PrestataireType'];
        this.params.GEDType = res.data['GEDType'];
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    setTimeout(() => {
      if(this.prestataire.id != null){
        var params = {
          'prestataire_id': this.prestataire.id
        }
        this.$store.back.ajax('/prestataire/comptes', params, (res) => {
          if(res.status){
            this.prestataire.type_id === 4 ? this.comptes_fournitures = res.data : this.comptes_prestataire = res.data;
          }
        });
      }
    },500);
  }
};
</script>
